import type { PaymentSuccessPartnerPromoCode } from '@backmarket/http-api/src/api-specs-b2c-services/partner-promo-code/types/partner-promo-code'
import type { Order } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'

export function getVisibleByVerizonPromoCode(orders: Order[]): string | null {
  let partnerPromoCode = null

  orders.forEach((order) =>
    order.orderlines.forEach((orderline) => {
      if (
        orderline.partnerPromoCode &&
        orderline.partnerPromoCode.partnerName === 'VISIBLE' &&
        Object.hasOwn(orderline.partnerPromoCode, 'codeValue')
      ) {
        partnerPromoCode = (
          orderline.partnerPromoCode as PaymentSuccessPartnerPromoCode
        ).codeValue
      }
    }),
  )

  return partnerPromoCode
}
